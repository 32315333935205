<template>
  <div id="app" class="has-background-light" :class="{'xmas': isXmasMonths }">
    <nav
      class="navbar is-primary"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <span style="font-weight: bold; font-size: 20px"
            >JMAG Fulfilment</span
          >
        </a>
        <a
          role="button"
          class="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-start">
          <router-link class="navbar-item" to="/" exact>Dashboard</router-link>
          <router-link class="navbar-item" to="/Help" exact>Help</router-link>
          <router-link class="navbar-item" to="/fulfilment"
            >Fulfilment</router-link
          >
          <router-link
            class="navbar-item"
            to="/batch-jobs"
            v-if="hasFullAccess()"
            >Batch Jobs</router-link
          >
          <router-link
            class="navbar-item"
            to="/batch-destination"
            v-if="hasFullAccess()"
            >Batch Destination</router-link
          >
          <router-link
            class="navbar-item"
            to="/product-config"
            v-if="hasFullAccess()"
            >Product Config</router-link
          >
          <router-link
            class="navbar-item"
            to="/contact-group"
            v-if="hasFullAccess()"
            >Contact Groups</router-link
          >
          <router-link class="navbar-item" to="/search-legacy-new"
            >Quick Search</router-link
          >
          
        </div>
      </div>
    </nav>
    <div class="container has-background-white">
      <section style="padding: 10px 20px; border-bottom: solid #eee 2px">
        <nav class="level">
          <!-- Left side -->

          <div class="level-left" v-if="isAuthenticated">
            <b class="level-item">
              {{ hasFullAccess() ? "Admin User" : "Read Only User" }} -
              {{ isAuthenticated.profile.email }}
            </b>
          </div>

          <!-- Right side -->
          <div class="level-right">
            <p class="level-item">
              <a href="/aad-signout">Signout</a>
            </p>
          </div>
        </nav>
      </section>

      <section class="section">
        <router-view />
      </section>
    </div>
  </div>
</template>
<script>
import { baseMixin } from "../mixins/baseMixin.js";

export default {
  mixins: [baseMixin],
  data: function () {
    return {};
  },
  created(){
    if(this.isXmasMonths && false){
      var sf = new Snowflakes({
          color: "#edf9fd",
          count: 100,
          minSize: 20,
          maxSize: 45
      });
    }
  },
  computed: {
    isXmasMonths() {
      return new Date().getMonth() >= 7 ;
    }
  }
};
</script>