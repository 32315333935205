export const baseMixin = {
  methods: {
    hasFullAccess() {
      var restrictAccess =
        process.env.VUE_APP_OVERRIDE_ACCESS_TORESTRICTED == "true";
      var email = this.$adal.user.profile.email;
      return (
        this.isAuthenticated &&
        (email.indexOf("jellyfish.com") >= 0 ||
          email.indexOf("jellyfishconnect.com") >= 0) &&
        !restrictAccess
      );
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
    },
    getDateAsISOFormat(date) {
      //=2019-05-01
      var d = date
        .getDate()
        .toString()
        .padStart(2, "0");
      var m = (date.getMonth() + 1).toString().padStart(2, "0");
      var y = date.getFullYear();
      return `${y}-${m}-${d}`;
    },
    FailedAxios(error) {
      this.$toast.open(`Failed Request: ${error.message}`);
      console.log(error);
    },
    catchError(res) {
      var msg = `Failed - Reason: ${res.response.data.Message}`;
      //16 charactes per second
      var duration = msg.length * 50; //50ms per character to read it;

      this.$toast.open({
        message: msg,
        duration: duration,
        type: "is-danger",
      });
    },
    addressAsString(contact, addressString) {
      //"billing_contact": { "billing_contact_title": "Mrs", "billing_contact_first_name": "Michelle", "billing_contact_last_name": "Daggett", "billing_contact_email": "michelledaggett0@gmail.com", "billing_contact_phone": "", "billing_contact_address1": "211 Rochdale Road", "billing_contact_address2": "Ramsbottom", "billing_contact_address3": "", "billing_contact_town": "Bury", "billing_contact_county": "Lancashire", "billing_contact_postcode": "BL0 0RG", "billing_contact_country": "UK" }

      //{{fulfilment.Data.products_info.offer_misc.offer_type}}
      var str = "";
      str += this.addBreakIfNotEmpty(
        contact[addressString + "_title"] +
          " " +
          contact[addressString + "_first_name"] +
          " " +
          contact[addressString + "_last_name"]
      );
      str += this.addBreakIfNotEmpty(contact[addressString + "_address1"]);
      str += this.addBreakIfNotEmpty(contact[addressString + "_address2"]);
      str += this.addBreakIfNotEmpty(contact[addressString + "_address3"]);
      str += this.addBreakIfNotEmpty(contact[addressString + "_town"]);
      str += this.addBreakIfNotEmpty(contact[addressString + "_county"]);
      str += this.addBreakIfNotEmpty(contact[addressString + "_postcode"]);
      str += this.addBreakIfNotEmpty(contact[addressString + "_country"]);
      return str;
    },
    addBreakIfNotEmpty(appendString) {
      return (
        appendString +
        (appendString != null && appendString.length > 0 ? "<br>" : "")
      );
    },
    copyToClipboard(containerid) {
      var target = document.getElementById(containerid);

      var success = false;
      if (target.type === "textarea") {
        target.select();
        target.setSelectionRange(0, 99999); /*For mobile devices*/
        success = document.execCommand("copy");
      } else {
        this.selectElement(target);
        success = document.execCommand("copy");
      }

      this.deselectElements();

      this.$toast.open(
        success ? "Copied to clipboard" : "Failed to copy to clipboard"
      );
    },
    asMoney(value) {
      return "£" + parseFloat(value).toFixed(2);
    },
    selectElement(element) {
      if (window.getSelection) {
        var range = document.createRange();
        range.selectNodeContents(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      } else if (document.selection) {
        var range = document.body.createTextRange();
        range.moveToElementText(element);
      } else {
        console.error("COULDN'T SELECT :/");
      }
    },
    deselectElements() {
      window.getSelection().removeAllRanges();
    },
    fieldCharCount(fieldLength, limit) {
      return fieldLength + " / " + limit;
    },
    fieldWarningMessage(fieldLength, limit) {
      return fieldLength > limit;
    },
    fulfilmentCanBeRefundedFromFraud(f) {
      return this.fulfilmentCannotBeRefundedReason(f) === "";
    },
    fulfilmentCanRefundText(f) {
      return f.Data.products_info.payment_method == "CC"
        ? this.fulfilmentCanBeRefundedFromFraud(f)
          ? "Yes"
          : "No"
        : "-";
    },
    fulfilmentCannotBeRefundedReason(f) {
      //Direct debits can always be refunded as we don't acutally take payment
      if (f.Data.products_info.payment_method === "DD") return "";

      //For Credit Cards
      //needs to be after given date, Needs refund object if SagePay and needs to be single cart item
      if (this.fulfilmentIsMultiCart(f)) return "Is multi cart order";

      if (!this.fulfilmentHasRefundData(f))
        return "Is missing data to perform refund (older orders generally)";

      if (!this.fulfilmentAfterTotalCartDate(f))
        return "Order is too old, is missing data";

      //can refund
      return "";
    },
    fulfilmentHasRefundData(f) {
      switch (f.Data.products_info.payment_method) {
        case "DD":
          return true;
        case "CC":
          switch (f.Data.payment_info.CC.card_type) {
            case "SagePay":
              return f.Data.meta_info.refund !== null;
            default:
              return true;
          }
      }
    },
    fulfilmentIsMultiCart(f) {
      return (
        f.Data.other.is_JMAG == "1" &&
        parseFloat(f.Data.products_info.offer_price) !==
          parseFloat(f.Data.other.subtotal)
      );
    },
    fulfilmentAfterTotalCartDate(f) {
      var dateOrdersGotFullCartOrderAmounts = new Date("2020-08-11 08:45:00");
      return new Date(f.CreatedAt) > dateOrdersGotFullCartOrderAmounts;
    },
    deepCopyFunction(inObject) {
      let outObject, value, key;

      if (typeof inObject !== "object" || inObject === null) {
        return inObject; // Return the value if inObject is not an object
      }

      // Create an array or object to hold the values
      outObject = Array.isArray(inObject) ? [] : {};

      for (key in inObject) {
        value = inObject[key];

        // Recursively (deep) copy for nested objects, including arrays
        outObject[key] = this.deepCopyFunction(value);
      }

      return outObject;
    },
  },
};
