<template>
            <div style="width:100%">
             <input :type="passwordFieldType" class="form-control" v-model="value">
              <span @click="switchVisibility" class="fa fa-fw fa-eye field-icon toggle-password"></span>
            </div>
</template>

<script>
   import { abstractField } from "vue-form-generator";
   export default {
         mixins: [ abstractField ],
          components: { },
         data() {
             return{
                 passwordFieldType: "password"
             }
         },
         methods:{
            switchVisibility() {
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
            }
         }
   };
</script>

<style scoped>
    .field-icon {
        cursor: pointer;
        float: right;
        padding-right: 25px;
        margin-top: -25px;
        position: relative;
        z-index: 2;
    }
</style>