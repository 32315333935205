import Vue from "vue";
import Vuex from "vuex";

import contactGroup from "../services/contactGroups.js";
Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    flavor: "berry",

    contactGroupsBasic: null,
  },
  mutations: {
    changeFlavor(state, flavor) {
      state.flavor = flavor;
    },
    updateContactGroupBasic(state, contactGroupUpdated) {
      alert("yay");
    },
  },
  getters: {
    flavor: (state) => state.flavor,
  },
  actions: {
    async saveContactGroup({ commit }) {
      const user = await contactGroup.save();
      commit("updateContactGroupBasic", response.data);
    },
  },
});
