import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import axios from "axios";
import VueAxios from "vue-axios";

import { default as Adal } from "vue-adal";

import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css";
import fieldCustompassword from "./components/atom/fieldCustompassword.vue";
Vue.component("fieldCustompassword", fieldCustompassword);

import Buefy from "buefy";
import "buefy/dist/buefy.css";

import { baseMixin } from "../mixins/baseMixin.js";

var postloginurl = process.env.VUE_APP_POSTLOGINURL;

Vue.use(Adal, {
  config: {
    tenant: "e2458d43-9af5-4562-86b3-2056da5b537e",
    clientId: "8a1940e4-bdd9-420d-af2a-19f1d8f6e13d",
    redirectUri: postloginurl, //PUT THIS AS APP SETTING SO ITS this on live site! > //https://mcfulfilmentwebsite.z35.web.core.windows.net/ //"http://localhost:8080"
    cacheLocation: "localStorage",
  },
  requireAuthOnInitialize: true,
  router: router,
});

Vue.use(Buefy, {
  defaultIconPack: "fa",
  defaultToastDuration: 4000,
});

axios.interceptors.request.use(
  function(config) {
    let authenticationContext = window.App.$adal.adalContext; // Obtain adal reference via window object
    authenticationContext.acquireToken(
      authenticationContext.config.clientId,
      (err, token) => {
        if (err) {
          let errCode = err.split(":")[0];
          switch (errCode) {
            case "AADSTS50058": // Need to prompt for user sign in
              console.log("CODE: " + errCode);
              authenticationContext.login();
              break;
            case "AADSTS65001": // Token is invalid; grab a new one
              console.log("CODE: " + errCode);
              authenticationContext.acquireTokenRedirect(resource);
              break;
            case "AADSTS16000": // No Access
            default:
              console.log("CODE: " + errCode);
              // Need a pop-up forcing a login
              authenticationContext.login();
              break;
          }
          return;
        }
        config.headers = {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": "api key",
          Authorization: "Bearer " + token,
        };
      }
    );

    return config;
  },
  function(error) {
    console.log("promise reject " + error);
    return Promise.reject(error);
  }
);

Vue.use(VueAxios, axios);
Vue.use(VueFormGenerator);

import VCalendar from "v-calendar";
Vue.use(VCalendar, {
  // transition: "slide-h",
  // datePicker: {
  //   popover: {
  //     keepVisibleOnInput: true,
  //     placement: "top"
  //   },
  //   inputDebounce: 5000,
  //   updateOnInput: false
  // }
  // componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

Vue.config.productionTip = false;

require("./assets/main.scss");

router.beforeEach((to, from, next) => {
  // next();

  var username = window._adalInstance._user.profile.email;
  var isAdmin =
    username.indexOf("jellyfishconnect.com") >= 0 ||
    username.indexOf("jellyfish.com") >= 0;

  // console.log(username);
  // console.log("ISADMIN: " + isAdmin);
  // console.log(window.msal.account.idTokenClaims.name);

  if (to.matched.some((record) => record.meta.is_admin)) {
    if (isAdmin) {
      next();
    } else {
      next("/");
      alert("no permissions");
    }
  } else {
    next();
  }
});

import { store } from "./store/store";
// store.$axios = VueAxios;

window.App = new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

document.addEventListener("DOMContentLoaded", () => {
  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(
    document.querySelectorAll(".navbar-burger"),
    0
  );

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach((el) => {
      el.addEventListener("click", () => {
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle("is-active");
        $target.classList.toggle("is-active");
      });
    });
  }
});
