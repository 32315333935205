import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

//https://www.digitalocean.com/community/tutorials/how-to-set-up-vue-js-authentication-and-route-handling-using-vue-router#step-3-%E2%80%94-updating-the-vue-router-file

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Dashboard",
      component: () => import("./views/Dashboard.vue"),
    },
    {
      path: "/help",
      name: "Help",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("./views/Help.vue"),
    },

    {
      path: "/fulfilment",
      name: "Fulfilment Orders",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("./views/Fulfilment.vue"),
    },
    {
      path: "/batch-jobs",
      name: "Batch Jobs",
      meta: {
        is_admin: true,
      },
      component: () => import("./views/BatchJobs.vue"),
    },
    {
      path: "/batch-destination",
      name: "Batch Destination",
      meta: {
        is_admin: true,
      },
      component: () => import("./views/BatchDestination.vue"),
    },
    {
      path: "/product-config",
      name: "Product Config",
      meta: {
        is_admin: true,
      },
      component: () => import("./views/ProductConfig.vue"),
    },
    {
      path: "/search-legacy-new",
      name: "Search Order Legacy vs New",
      component: () => import("./views/SearchLegacyNew.vue"),
    },
    {
      path: "/view-user-details/:id",
      name: "View User Details",
      component: () => import("./views/ViewDetails.vue"),
    },
    {
      path: "/page/:id",
      name: "Terms",
      component: () => import("./views/CMSPage.vue"),
    },
    {
      path: "/contact-group",
      name: "Contact Groups",
      meta: {
        is_admin: true,
      },
      component: () => import("./views/ContactGroups.vue"),
    },
  ],
});
